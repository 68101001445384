.row_container_setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 100px;
}
.myGift_title_setting {
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  color: #2264ae;
}
.cardStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 0px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  margin-bottom: 15px;
}
.cardStyles > div {
  padding: 10px 24px 20px 24px !important;
  width: 100%;
}
.cardStyles_Container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 0px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  margin-bottom: 20px;
  //margin-top: 20px;
}
.cardStyles_Container > div {
  padding: 26px 24px 16px 24px !important;
  width: 100%;
}

.container {
  padding: 5px 100px;
}
.cardContainer {
  margin-top: 30px;
  margin-left: 150px;
  margin-right: 150px;
}
.card_content {
  width: 100%;
  //margin-top: 10px;
}
.card_content > div {
  margin-bottom: 0px !important;
}
.card_title {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #344054 !important;
}

.select_styles > div > label {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > span > textarea {
  font-size: 12px;
}
.import_style_reject {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #d92d20;
  color: white;
  padding-bottom: 21px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
}
.import_style_reject:hover {
  background-color: white !important;
  color: #d9534f;
  border-color: #d9534f;
}
.import_style_reject:active {
  background-color: #d92d20 !important;
  color: white;
}
.import_style_reject:focus {
  background-color: #d92d20 !important;
  color: white;
}

.import_style_cancel {
  font-size: 10px !important;
  font-weight: 600 !important;
  padding-bottom: 21px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 6px !important;
}
.not_allowed_icon > div > div > div > span {
  font-size: 16px;
  // color: #2364ad !important;
  font-weight: 600;
}

.ExceptionalApproval_button {
  padding: 0px 25px;
  height: 38px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.cancel_rejectModal_button {
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 38px !important;
  line-height: 1.1 !important;
  padding: 0px 25px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.cancel_rejectModal_button:hover {
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.cancel_rejectModal_button:active {
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.textarea_styles {
  font-size: 12px;
  color: black;
  font-family: 'SFProText Regular';
}
