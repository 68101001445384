.card_container {
  //  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  //  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.card_content_style {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  //padding: 15px 0px 0px 15px;
  padding-top: 15px;
  isolation: isolate;
  background: #ffffff;
  border: 1px solid #f4f4f4;
  box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
    0px 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  width: 245px;
  height: 100px;
  // margin-right: 10px;
}
.card_space_between {
  display: flex;
  justify-content: space-between;
  gap: 36px !important;
}

.card_icon_div {
  margin-right: 10px;
  // margin-top: 1px;
}
.card_icon_style {
  font-size: 20px;
  color: #4059e5;
}
.card_icon_style_img {
  width: 26px !important;
  height: 26px !important;
  color: #4059e5 !important;
}
.card_title_style {
  font-size: 15px;
  color: #2264ae;
  font-weight: 600;
  line-height: 36px;
  //margin-left: 10px;
  text-align: center;
  margin-top: 2px;
}
.card_amount_style {
  // margin-top: 14px;
  font-size: 22px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  line-height: 38px;
  text-align: center;
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: space-around;
}
.gift_icon_size {
  width: 28px;
}
.gift_pending_icon_size {
  width: 22px;
}
.gift_antd_icon_size {
  color: #2264ae;
  font-size: 26px;
}
.a_color {
  color: #2264ae;
}
.cardLink_title {
  color: #2264ae;
}
.cardLink_title:hover {
  color: #071bef;
}
@media only screen and (max-width: 1348px) and (min-width: 1194px) {
}
@media only screen and (max-width: 2260px) and (min-width: 1560px) {
}
@media only screen and (max-width: 1560px) and (min-width: 1348px) {
}
@media only screen and (max-width: 1198px) and (min-width: 770px) {
}
/* For Mobile */
@media (max-width: 575px) {
  .card_content_style {
    width: 230px;
    height: 90px;
  }
  .card_amount_style {
    font-size: 22px;
    margin-top: -3px;
  }
}
