.switcher_container {
  background: #ebeff3;
  border: 1px solid #dcdce4;
  border-radius: 10px;
  padding: 10px 14px;
  :global(.ant-typography) {
    color: #000000;
  }
  :global(.ant-switch) {
    background: #2264ae;
  }
}
