.bar_container {
  max-width: 500px;
  width: 500px;
}
/* For Mobile */
@media (max-width: 575px) {
  .bar_container {
    max-width: 300px;
    width: 300px;
  }
}
