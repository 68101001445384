/*----------!!Important !! height of the cell of the table----------------------  */
:global(.ant-table) {
  line-height: 1 !important;
}
/* ------------------------------------------- */
.custom_table_container {
  height: 100%;
  .empty_state_text {
    color: #616187;
    margin: 20px 0 10px;
  }
  :global(.ant-table-thead) {
    :global(.ant-table-cell) {
      font-family: 'SFProText Bold';
      padding: 10px 4px 10px 10px; // table header //
      // text-align: center;
      :global(.ant-checkbox-checked .ant-checkbox-inner) {
        background-color: #4945ff;
        border-color: #4945ff;
      }
    }
  }
  :global(.ant-table-wrapper) {
    height: 100%;

    :global(.ant-spin-nested-loading) {
      height: 100%;

      :global(.ant-spin-container) {
        height: 100%;
        display: flex;
        flex-flow: column nowrap;

        :global(.ant-table) {
          flex: auto;
          overflow: hidden;

          :global(.ant-table-container) {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            :global(.ant-table-thead) {
              flex: none;
            }

            :global(.ant-table-tbody) {
              flex: auto;
              overflow: scroll;
              :global(.ant-table-cell) {
                font-family: 'SFProText Regular';
                padding: 8px 4px 8px 10px; // table cell //
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 16px;
                color: #32324d;
                line-height: 0.5 !important;
                //text-align: center;
                :global(.ant-checkbox-checked .ant-checkbox-inner) {
                  background-color: #4945ff;
                  border-color: #4945ff;
                }
              }
              :global(.ant-table-placeholder) {
                :global(.ant-table-cell) {
                  border-bottom: none;
                }
              }
            }
          }
        }

        :global(.ant-table-pagination) {
          flex: none;
        }
      }
    }
  }
  &.no_data {
    :global(.ant-table-content) {
      height: 100%;
      table {
        height: calc(100% - 55px);
        :global(.ant-table-tbody) {
          height: 100%;
        }
      }
    }
  }
}
/* ------new Table Style--------- */
