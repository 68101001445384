.row_container_setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 100px;
  padding-right: 100px;
}
.container {
  padding: 15px 100px;
}
.row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.myGift_title {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: #2264ae;
  // background-color: white;
  // padding: 100px 50px 50px;
}
.myGift_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.cardContainer {
  margin-top: 5px;
  margin-left: 150px;
  margin-right: 150px;
}
.cardStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 0px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  margin-bottom: 10px;
}
.cardStyles > div {
  padding: 10px 24px 10px 24px !important;
  width: 100%;
}
.card_content {
  font-weight: 600;
  font-size: 16px;
}
.icon_size {
  font-size: 30px;
}
.card_content_text {
  font-size: 16px;
  margin-left: 10px;
  margin-top: 10px;
}
.gift_icon_size {
  margin-top: 20px;
}
.back_btn {
  float: right;
  display: flex;
  justify-content: flex-end;
  font-weight: 600;
  margin-top: 10px;
}

/* For Mobile */
@media (max-width: 575px) {
  .row_container_setting {
    padding-left: 10px;
    padding-right: 10px;
  }
  .container {
    padding: 15px 10px;
  }
  .cardContainer {
    margin-top: 5px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
