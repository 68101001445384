.government_official_size {
  // overflow-y: scroll;
  // overflow-y: auto;
  // overflow: auto;
  // height: 300px;
}
.select_styles > div > label {
  height: 33px !important;
  font-size: 12px;
}
.select_styles > div > div > div > span > textarea {
  font-size: 12px;
}
.import_style_reject {
  font-size: 10px !important;
  font-weight: 600 !important;
  background-color: #d92d20;
  color: white;
  padding-bottom: 21px !important;
  padding-left: 40px !important;
  padding-right: 40px !important;
  padding-top: 6px !important;
}
.import_style_reject:hover {
  background-color: white !important;
  color: #d9534f;
  border-color: #d9534f;
}
.import_style_reject:active {
  background-color: #d92d20 !important;
  color: white;
}
.import_style_reject:focus {
  background-color: #d92d20 !important;
  color: white;
}

.import_style_cancel {
  font-size: 10px !important;
  font-weight: 600 !important;
  padding-bottom: 21px !important;
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 6px !important;
}
.not_allowed_icon > div > div > div > span {
  font-size: 16px;
  // color: #2364ad !important;
  font-weight: 600;
}
/* .reject_modal_button {
  font-size: 12px !important;
  font-weight: 600 !important;
  height: 34px !important;
  line-height: 1.1 !important;
  padding: 0px 20px;
}
.reject_modal_button:hover {
  color: #d9534f !important;
  border-color: #d9534f !important;
}
.reject_modal_button:active {
  color: #d9534f !important;
  border-color: #d9534f !important;
} */
.ExceptionalApproval_button {
  padding: 0px 25px;
  height: 38px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.cancel_rejectModal_button {
  font-size: 14px !important;
  font-weight: 600 !important;
  height: 38px !important;
  line-height: 1.1 !important;
  padding: 0px 25px;
  border-radius: 8px;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
}
.cancel_rejectModal_button:hover {
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.cancel_rejectModal_button:active {
  color: #2364ad !important;
  border-color: #2364ad !important;
}
.textarea_styles {
  font-size: 12px;
  color: black;
  font-family: 'SFProText Regular';
}
.formItem_approval {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.formItem_approval > div {
  margin-bottom: 0px !important;
  padding-bottom: 0px !important;
}
.return_title_RO {
  font-size: 13px;
  margin-top: -20px;
  font-weight: 600;
  font-family: 'SFProText Regular';
}
.return_subtitle_RO {
  font-size: 12px;
  font-weight: 500;
  font-family: 'SFProText Regular';
  margin-bottom: 30px;
}
