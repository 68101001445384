.container {
  padding: 30px 100px;
  font-family: 'SFProText Regular';
}
.row_container {
  display: flex;
  flex-direction: row;
  //justify-content: space-between;
}

.myGift_title {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}
.myGift_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.mySubGift_title {
  font-weight: 700;
  font-size: 24px;
  line-height: 38px;
  color: #101828;
}
.card_styles {
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 8px;
  padding: 25px;
}
.select_styles label {
  font-size: 12px !important;
}
.select_styles > div > div {
  height: 13px !important;
  font-size: 12px;
  font-family: 'SFProText Regular';
  min-height: 20px !important;
}
.Goback_button {
  padding: 0px 25px;
  height: 38px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.Goback_button1 {
  padding: 0px 25px;
  height: 38px;
  background: white;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #2264ae;
}
.Goback_button1:hover {
  background: #2264ae;
  border: 1px solid #2264ae;
  color: white;
}
.Goback_button_reject {
  padding: 0px 25px;
  height: 38px;
  background: white;
  border: 1px solid #b71212;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #b71212;
}
.Goback_button_reject:hover {
  background: #b71212;
  border: 1px solid #b71212;
  color: white;
}
.li_style {
  display: block;
}
.valueText {
  color: #344054;
  font-weight: 500;
  font-size: 13px;
  font-family: 'SFProText Regular' !important;
  text-transform: capitalize;
}
.return_title_div {
  display: flex;
  justify-content: end;
  margin-top: -5px;
  margin-bottom: -10px;
}
.divider_margin {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
}
.CardTitleText {
  color: #2264ae;
  font-weight: 700;
  font-size: 18px;
  font-family: 'SFProText Regular' !important;
  text-transform: capitalize;
}
.ant_collapse_header {
  // width: 150px;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;
  color: rgba(0, 0, 0, 0.45);
  margin-left: 4px;
}
.steper_comments {
  color: rgba(0, 0, 0, 0.6);
  font-weight: 600;
}
:global(.ant-steps-item-finish .ant-steps-item-icon) {
  border-color: rgb(3, 170, 3);
}
:global(.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon) {
  color: rgb(3, 160, 3);
}
:global(.ant-steps-item-finish
    > .ant-steps-item-container
    > .ant-steps-item-content
    > .ant-steps-item-title::after) {
  background-color: #2264ae;
}
:global(.ant-steps-item-process
    > .ant-steps-item-container
    > .ant-steps-item-icon) {
  background: #2264ae;
}
