.full_container_div {
  // position: relative;
  width: auto !important;
  max-width: 100%;
  height: 100%;
  background-color: rgb(255, 255, 255);
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
.rightBackground_div {
  width: 100%;
}
.gift_rightBackground_img {
  z-index: 1;
  margin-left: 34%;
  position: absolute;
  margin-top: 1rem;
  height: 612px;
  overflow: hidden;
  overflow-y: hidden;
  overflow-x: hidden;
}
.leftBackground_div {
  height: 100%;
}
.gift_leftBackground_img {
  z-index: 3;
  position: absolute;
  height: 700px;
}
.boy_giftBox_img {
  z-index: 5;
  position: absolute;
  margin-top: 7.5rem;
  //width: 100%;
  height: 480px;
  margin-left: 6%; //3%;
}
.left_logo {
  z-index: 5;
  position: absolute;
  height: 60px;
  width: 150px;
  margin-top: 1rem;
  margin-left: 5rem;
}
.rightTata_logo {
  z-index: 3;
  position: absolute;
  margin-left: 88%;
  height: 40px;
  width: 100px;
  margin-top: 1rem;
}
.welcome_text {
  z-index: 3;
  position: absolute;
  margin-left: 55%;
  top: 40%;
}
.welcome_text_title {
  font-size: 32px !important;
  font-weight: 700;
  //line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 5px;
}
.welcome_text_subtitle1 {
  font-size: 12px !important;
  font-weight: 500;
  //line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 1%;
  margin-bottom: 0px;
}
.welcome_text_subtitle2 {
  font-size: 12px !important;
  font-weight: 500;
  // line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
  margin-left: 1%;
  margin-top: 0px;
}
.login_button {
  height: 48px;
  width: 230px;
  left: 0px;
  top: 31px;
  border-radius: 8px;
  padding: 12px 25px 12px 25px;
  background: rgba(34, 100, 174, 1);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  color: white;
  margin-left: 1%;
  font-weight: 600;
  font-size: 14px !important;
}
@media only screen and (max-width: 1600px) and (min-width: 1422px) {
  .gift_rightBackground_img {
    height: 715px;
    margin-left: 15%;
    width: 85%;
    margin-top: 3rem;
  }
  .gift_leftBackground_img {
    height: 825px;
  }
  .boy_giftBox_img {
    z-index: 5;
    position: absolute;
    margin-top: 10.5rem;
    height: 580px;
    margin-left: 3%;
  }
  .left_logo {
    z-index: 5;
    position: absolute;
    height: 70px;
    width: 180px;
    margin-top: 1rem;
    margin-left: 5rem;
  }
  .rightTata_logo {
    z-index: 3;
    position: absolute;
    margin-left: 88%;
    height: 50px;
    width: 120px;
    margin-top: 1rem;
  }
  .welcome_text {
    z-index: 3;
    position: absolute;
    margin-left: 51%;
    top: 40%;
  }
  .welcome_text_title {
    font-size: 36px !important;
  }
  .welcome_text_subtitle1 {
    font-size: 16px !important;
  }

  .login_button {
    height: 55px;
    width: 300px;
  }
  .button_title {
    font-size: 18px;
  }
}
@media only screen and (max-width: 1423px) and (min-width: 1290px) {
  .gift_rightBackground_img {
    margin-left: 16%;
    width: 84%;
    margin-top: 3rem;
  }
  .gift_leftBackground_img {
    z-index: 3;
    position: absolute;
    height: 700px;
  }
  .boy_giftBox_img {
    z-index: 5;
    position: absolute;
    margin-top: 8rem;
    //width: 100%;
    height: 530px;
    margin-left: 3%;
  }
  .welcome_text {
    margin-left: 53%;
    top: 45%;
  }
  .welcome_text_title {
    font-size: 34px !important;
  }
  .login_button {
    width: 250px;
  }
}
@media only screen and (max-width: 1164px) and (min-width: 1025px) {
  .gift_rightBackground_img {
    margin-left: 34%;

    height: 612px;
    width: 66%;
  }
  .boy_giftBox_img {
    z-index: 5;
    position: absolute;
    margin-top: 4rem;
    height: 480px;
    margin-left: 3%;
  }
  .welcome_text {
    margin-left: 58%;
    top: 48%;
  }
  .welcome_text_title {
    font-size: 26px !important;
  }
  .login_button {
    width: 200px;
  }
}
@media only screen and (max-width: 1024px) and (min-width: 854px) {
  .gift_rightBackground_img {
    z-index: 1;
    margin-left: 30%;
    position: absolute;
    margin-top: 0rem;
    height: 512px;
    width: 70%;
  }
  .leftBackground_div {
    height: 100%;
  }
  .gift_leftBackground_img {
    z-index: 3;
    position: absolute;
    height: 535px;
  }
  .boy_giftBox_img {
    z-index: 5;
    position: absolute;
    margin-top: 5.5rem;
    //width: 100%;
    height: 390px;
    margin-left: 3%;
  }
  .welcome_text {
    z-index: 3;
    position: absolute;
    margin-left: 54%;
    top: 40%;
  }
  .welcome_text_title {
    font-size: 24px !important;
    font-weight: 700;
    //line-height: 41px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 5px;
  }
  .welcome_text_subtitle1 {
    font-size: 11px !important;
  }
  .login_button {
    height: 48px;
    width: 200px;
  }
}
/* @media only screen and (max-width: 420px) and (min-width: 320px) and (max-height: 920px) and (min-height: 667px) { */
@media only screen and (max-width: 768px) and (min-width: 320px) {
  .boy_giftBox_img {
    z-index: 5;
    position: absolute;
    margin-top: 11rem;
    height: 380px;
    width: 380px;
    margin-left: -3%;
  }
  .left_logo {
    z-index: 5;
    position: absolute;
    height: 60px;
    width: 150px;
    margin-top: 3rem;
    margin-left: 2rem;
  }
  .rightTata_logo {
    z-index: 3;
    position: absolute;
    margin-left: 88%;
    height: 40px;
    width: 100px;
    margin-top: 1rem;
    display: none;
  }
  .welcome_text {
    z-index: 3;
    position: absolute;
    margin-left: 7%;
    top: 18%;
  }
  .welcome_text_title {
    font-size: 24px !important;
    font-weight: 700;
    //line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 5px;
  }
  .login_button {
    height: 48px;
    width: 230px;
    left: 0px;
    // top: 70%;
    margin-top: 120%;
    margin-left: 15%;
    border-radius: 8px;
    padding: 12px 25px 12px 25px;
    background: rgba(34, 100, 174, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;

    font-weight: 600;
    font-size: 14px !important;
  }
  .welcome_text_subtitle1 {
    font-size: 12px !important;
    font-weight: 500;
    //line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 1%;
    margin-bottom: 0px;
    display: none;
  }
  .gift_rightBackground_img {
    z-index: 1;
    margin-left: 34%;
    position: absolute;
    margin-top: 1rem;
    height: 612px;
    display: none;
  }

  .gift_leftBackground_img {
    z-index: 3;
    position: absolute;
    height: 700px;
    display: none;
  }
}
@media only screen and (max-width: 844px) and (min-width: 390px) {
  .boy_giftBox_img {
    z-index: 5;
    position: absolute;
    margin-top: 10rem;
    height: 480px;
    width: 400px;
    margin-left: -3%;
    // margin-right: 50px;
  }
  .left_logo {
    z-index: 5;
    position: absolute;
    height: 60px;
    width: 150px;
    margin-top: 3rem;
    margin-left: 2rem;
  }
  .rightTata_logo {
    z-index: 3;
    position: absolute;
    margin-left: 88%;
    height: 40px;
    width: 100px;
    margin-top: 1rem;
    display: none;
  }
  .welcome_text {
    z-index: 3;
    position: absolute;
    margin-left: 7%;
    top: 18%;
  }
  .welcome_text_title {
    font-size: 24px !important;
    font-weight: 700;
    //line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 5px;
  }
  .login_button {
    height: 48px;
    width: 230px;
    left: 0px;
    // top: 70%;
    margin-top: 130%;
    margin-left: 16%;
    border-radius: 8px;
    padding: 12px 25px 12px 25px;
    background: rgba(34, 100, 174, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;

    font-weight: 600;
    font-size: 14px !important;
  }
  .welcome_text_subtitle1 {
    font-size: 12px !important;
    font-weight: 500;
    //line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 1%;
    margin-bottom: 0px;
    display: none;
  }
  .gift_rightBackground_img {
    z-index: 1;
    margin-left: 34%;
    position: absolute;
    margin-top: 1rem;
    height: 612px;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    display: none;
  }

  .gift_leftBackground_img {
    z-index: 3;
    position: absolute;
    height: 700px;
    display: none;
  }
}
/* @media only screen and (max-width: 420px) and (min-width: 375px) and (max-height: 920px) and (min-height: 667px) {
  .boy_giftBox_img {
    z-index: 5;
    position: absolute;
    margin-top: 8rem;
    height: 480px;
    width: 400px;
    margin-left: -3%;
    // margin-right: 50px;
  }
  .left_logo {
    z-index: 5;
    position: absolute;
    height: 60px;
    width: 150px;
    margin-top: 3rem;
    margin-left: 2rem;
  }
  .rightTata_logo {
    z-index: 3;
    position: absolute;
    margin-left: 88%;
    height: 40px;
    width: 100px;
    margin-top: 1rem;
    display: none;
  }
  .welcome_text {
    z-index: 3;
    position: absolute;
    margin-left: 7%;
    top: 18%;
  }
  .welcome_text_title {
    font-size: 24px !important;
    font-weight: 700;
    //line-height: 41px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 5px;
  }
  .login_button {
    height: 48px;
    width: 230px;
    left: 0px;
    // top: 70%;
    margin-top: 120%;
    margin-left: 15%;
    border-radius: 8px;
    padding: 12px 25px 12px 25px;
    background: rgba(34, 100, 174, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;

    font-weight: 600;
    font-size: 14px !important;
  }
  .welcome_text_subtitle1 {
    font-size: 12px !important;
    font-weight: 500;
    //line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    margin-left: 1%;
    margin-bottom: 0px;
    display: none;
  }
  .gift_rightBackground_img {
    z-index: 1;
    margin-left: 34%;
    position: absolute;
    margin-top: 1rem;
    height: 612px;
    overflow: hidden;
    overflow-y: hidden;
    overflow-x: hidden;
    display: none;
  }

  .gift_leftBackground_img {
    z-index: 3;
    position: absolute;
    height: 700px;
    display: none;
  }
}
 */
/* For Mobile */
@media (max-width: 575px) {
  .login_button {
    height: 48px;
    width: 230px;
    left: 0px;
    // top: 70%;
    margin-top: 120%;
    margin-left: 15%;
    border-radius: 8px;
    padding: 12px 25px 12px 25px;
    background: rgba(34, 100, 174, 1);
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    color: white;

    font-weight: 600;
    font-size: 14px !important;
  }
}
