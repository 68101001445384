.customlayout_container {
  width: 100%;
}
.site_layout {
  width: 100%;
}
.site_layout_header {
  background-color: #2264ae !important;
  position: fixed;
  width: 100%;
  z-index: 1;
}
:global(.ant-layout-header) {
  padding-left: 20px;
  padding-right: 20px;
}
.active {
  font-weight: 600;
  padding-right: 15px;
  padding-top: 5px;
  padding-bottom: 7px;
  padding-left: 15px;
  border-bottom: 2px solid white !important;
}
:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected) {
  color: white;
}
:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected) {
  color: white;
}
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected {
  color: white;
}
.ant-menu-horizontal > .ant-menu-submenu .ant-menu-submenu-title:active {
  color: white;
}
/* .menuItems {
  background-color: #2264ae;
  display: flex;
  border-bottom: none;
  width: 100%;
  justify-content: space-around; // justify-content: center;
  :global(.ant-menu-item a) {
    color: rgb(255 255 255 / 85%);
  }
  :global(.ant-menu-item a:active) {
    background-color: white;
    color: #4945ff;
  }

  :global(.ant-menu-item a:hover) {
    font-weight: 600;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 5px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid white;

    color: #4945ff;

    .icon {
      filter: brightness(0) saturate(100%) invert(25%) sepia(83%)
        saturate(5163%) hue-rotate(240deg) brightness(102%) contrast(101%);
    }
  }
} */
:global(.ant-menu-horizontal:not(.ant-menu-dark)
    > .ant-menu-item-selected::after) {
  border-bottom: 2px solid #2264ae !important;
}

/* ---------------------------------------------------- */
.menuDropdown {
  background-color: #2264ae;
  display: flex;
  border-bottom: none;
  width: 100%;
  justify-content: center;
  color: white;
  :global(.ant-menu-item a) {
    color: rgb(255 255 255 / 85%);
  }
  :global(.ant-menu-item a:active) {
    background-color: white;
    //color: #4945ff;
    color: white;
  }
  /* :global(.ant-menu-item a:visited) {
    background-color: red;
    //color: #4945ff;
    color: red;
  } */

  :global(.ant-menu-item a:hover) {
    font-weight: 600;
    padding-right: 15px;
    padding-top: 5px;
    padding-bottom: 7px;
    padding-left: 15px;
    border-radius: 4px;
    background-color: white;
    border: 1px solid white;
    // color: white;
    color: #2264ae;

    .icon {
      filter: brightness(0) saturate(100%) invert(25%) sepia(83%)
        saturate(5163%) hue-rotate(240deg) brightness(102%) contrast(101%);
    }
  }
}
:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover) {
  background-color: #ffffff;
  color: #2264ae;
}
:global(.ant-menu-light .ant-menu-submenu-title:hover) {
  background-color: white;
  color: #2264ae;
  padding-right: 15px;
  padding-left: 15px;
}
:global(.ant-menu-horizontal
    > .ant-menu-submenu
    .ant-menu-submenu-title:hover) {
  background-color: white;
}
/* ---------------------------------------------------- */
:global(.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active)
  .icon_margin {
  margin-top: -1px;
  margin-bottom: 0;
  padding: 0 15px !important;
}
.gift_icon_size {
  width: 18px;
}
.gift_icon_size_admin {
  width: 35px;
}
.gift_received_size {
  width: 19px;
  //background-color: #2264ae;
}
.header_tata_logo {
  width: 100px;
}

@media (min-width: 576px) {
  .hamburger_menu {
    display: none;
  }
}

/* For Mobile */
@media (max-width: 575px) {
  .menuDropdown {
    display: none;
  }
  .hamburger_menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .hamburger_menu_icon {
    font-size: 26px;
    color: white;
  }
}

// .gift_icon_size:hover {
//   width: 18px;
//   color: #2264ae;
// }
// .gift_received_size:hover {
//   width: 19px;
//   color: #2264ae;
// }
// .gift_icon_size:active {
//   width: 18px;
//   color: #2264ae;
//   // background: #2264ae;
// }
// .gift_received_size:active {
//   width: 19px;
//   color: #2264ae;
//   // background: #2264ae;
// }
/* .custom_layout_container {
  min-height: 100vh;
  .sider_container {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #4059e5;
    box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);
    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 12px;
        background: #4059e5;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #4059e5;
          border-radius: 4px;
          margin-top: 14px;
          //  :global(.anticon) {
          //   //color: #abace2;
          //   color: #fff;
          // }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: #4059e5;
          }
        }
      }
    }
  }
  .sider_logo {
    height: 32px;
    margin: 16px;
  }

  .site_layout {
    margin-left: 64px;
    .site_layout_header {
      position: fixed;
      z-index: 1;
      width: calc(100% - 64px);
      background: #fff;
      padding: 0 10px 0 20px;
    }
    .site_layout_content {
      margin: 20px;
      margin-top: 80px;
    }
  }
}

.sidebar_icon {
  color: #fff !important;
}
.sidebar_icon:hover {
  color: #4059e5 !important;
  background: #fff !important;
}
.sidebar_icon:active {
  color: #4059e5 !important;
  background: #fff !important;
} */

/* .custom_layout_container {
  min-height: 100vh;
  .sider_container {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    background: #4059e5;
    box-shadow: 0px 0px 5px rgba(39, 49, 58, 0.1);
    :global(.ant-layout-sider-children) {
      :global(.ant-menu.ant-menu-inline-collapsed) {
        margin: 0 12px;
        background: #4059e5;
      }
      :global(.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)) {
        :global(.ant-menu-item) {
          margin: 0;
          background: #4059e5;
          border-radius: 4px;
          margin-top: 14px;
          //  :global(.anticon) {
          //   //color: #abace2;
          //   color: #fff;
          // }
        }
        :global(.ant-menu-item-selected) {
          background: #ffffff;
          :global(.anticon) {
            color: #4059e5;
          }
        }
      }
    }
  }
  .sider_logo {
    height: 32px;
    margin: 16px;
  }

  .site_layout {
    margin-left: 64px;
    .site_layout_header {
      position: fixed;
      z-index: 1;
      width: calc(100% - 64px);
      background: #fff;
      padding: 0 10px 0 20px;
    }
    .site_layout_content {
      margin: 20px;
      margin-top: 80px;
    }
  }
}

.sidebar_icon {
  color: #fff !important;
}
.sidebar_icon:hover {
  color: #4059e5 !important;
  background: #fff !important;
}
.sidebar_icon:active {
  color: #4059e5 !important;
  background: #fff !important;
} */
