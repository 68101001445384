.row_container_setting {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: white;
  padding-top: 20px;
  padding-bottom: 15px;
  padding-left: 100px;
}
.myGift_title_setting {
  font-weight: 700;
  font-size: 18px;
  line-height: 38px;
  color: #2264ae;
}
.container {
  padding: 25px 100px;
}
.row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.myGift_title {
  font-weight: 700;
  font-size: 25px;
  line-height: 38px;
  color: #101828;
}
.myGift_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.declareGift_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 14px;
  gap: 8px;
  //width: 150px;
  height: 40px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
  font-weight: 600;
}

.tabs_container {
  margin-top: 35px;
}

.cardStyles {
  width: 100%;
  top: 10px;
  margin-bottom: 20px;
}
.cardStyles > div {
  padding-top: 10px !important;
}
.table_container {
  //margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 12px;
  //
}
.table_container td {
  font-size: 12px;
}
.status_style {
  text-transform: uppercase !important;
  font-size: 11px !important;
}
.searchbar_div {
  width: 400px;
}
.searchbar > div > span {
  height: 34px;
}
.searchbar > div > span > input {
  font-size: 12px;
  // margin-left: 12px;
}
.searchbar > div > span {
  font-size: 12px !important;
}
.deactivate:hover {
  color: #054d9f;
}
.text_transform {
  text-transform: capitalize;
}
.edit_bu {
  font-size: 18px;
  margin-left: 15px;
}
.edit_bu:hover {
  color: #03b120;
}
.delete_bu {
  font-size: 18px;
  margin-left: 15px;
  color: #7d8089;
}
.delete_bu:hover {
  color: #ec0a0a;
}
.text_transform_role {
  text-transform: uppercase;
}
//-----------------------------------------------------------------------------------

/* .badgestyles > sup {
  height: 14px !important;
  font-size: 12px !important;
  background-color: #cbdfff;
  color: #1c4e9d;
  font-weight: 500;

  padding-top: 2px;
  padding-bottom: 15px;
}
.para_styles {
  font-weight: 400;
  font-size: 12px !important;
  line-height: 8px !important;
  font-style: normal;
}
.import_style {
  font-size: 12px !important;
  width: 90px;
  padding: 0px 19px !important;
}

.import_style_secondary {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #1c4e9d;
  color: #f0f0ff;
}
.import_style_secondary:hover {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:active {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.import_style_secondary:focus {
  font-size: 12px !important;
  font-weight: normal !important;
  background-color: #3c68ad !important;
  color: #f0f0ff;
}
.searchbar > div > span {
  font-size: 12px !important;
}
.table_container {
  margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 13px;
}
.table_container td {
  font-size: 13px;
}
.badgeStyles {
  background-color: #ecfdf3;
  padding: 8px 9px 7px 0px;
  border-radius: 12px;
}
.badgeStyles > span {
  color: #027a48;
  font-size: 12px;
}
.badgeReqStyles {
  background-color: #eff8ff;
  padding: 8px 9px 7px 0px !important;
  border-radius: 12px;
  text-transform: uppercase !important;
}
.badgeReqStyles > span {
  color: #175cd3;
  font-size: 12px;
}
.badgeRejStyles {
  background-color: #ffdada;
  padding: 8px 9px 7px 0px !important;
  border-radius: 12px;
  text-transform: uppercase !important;
}
.badgeRejStyles > span {
  color: #d92d20;
  font-size: 12px;
}
.badgeCloseStyles {
  background-color: #eaeaf2;
  padding: 3px 9px 4px 7px;
  border-radius: 12px;
}
.badgeCloseStyles {
  color: #18068b;
  font-size: 12px;
}
.badgePanStyles {
  background-color: #ffdada;
  padding: 8px 9px 7px 0px !important;
  border-radius: 12px;
}
.badgePenStyles > span {
  color: #d92d20;
  font-size: 12px;
}


.dateRange {
  height: 30px;
}
.badgeGpGenStyles {
  background-color: rgb(237, 247, 224);
  padding: 8px 9px 7px 0px !important;
  border-radius: 12px;
  text-transform: uppercase !important;
}
.badgeGpGenStyles > span {
  color: #059720;
  font-size: 12px;
}
.badgePartRecivStyles {
  background-color: #fdf2fa;
  padding: 8px 9px 7px 0px !important;
  border-radius: 12px;
  text-transform: uppercase !important;
}
.badgePartRecivStyles > span {
  color: #c11574;
  font-size: 12px;
}
.badgeGpPriStyles {
  background-color: #f2f4f7;
  padding: 8px 9px 7px 0px !important;
  border-radius: 12px;
}
.badgeGpPriStyles > span {
  color: #344054;
  font-size: 12px;
}
.badgeCanStyles {
  background-color: #f5cccf;
  padding: 3px 9px 4px 7px;
  border-radius: 12px;
}
.badgeCanStyles > span {
  color: #820b03;
  font-size: 12px;
}
.badgeCloseStyles {
  background-color: #dae6f8;
  padding: 3px 9px 4px 9px;
  border-radius: 12px;
  text-transform: uppercase !important;
}
.badgeCloseStyles > span {
  font-size: 12px;
} */
/**************Add New Style***********/
.page_title {
  color: #2364ad;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
}
.page_sub_title {
  color: #667085;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: -2px;
}
.Gate_pass_req_title {
  color: rgba(0, 0, 0, 0.7);
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
}
.Gate_pass_req_sub_title {
  color: #667085;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 2px;
}

.button_import_style {
  label {
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 30px !important; //Tab padding
    line-height: 32px !important;
    background-color: #1c4e9d;
    color: white;
  }
  label:hover {
    background-color: white;
    color: #1c4e9d;
  }
}

.customButton_import_style {
  font-size: 12px !important;
  width: 90px;
  height: 30px !important;
  padding: 0px 19px !important;
  padding-left: 14px !important;
}
.customButton_style_secondary {
  font-size: 12.5px !important;
  font-weight: 600 !important;
  background-color: #1c4e9d;
  color: white;
  height: 32px !important;
}
.customButton_style_secondary:hover {
  background-color: white;
  color: #1c4e9d;
}

.delete_icon > svg {
  height: 11.5px !important;
  width: 11.5px !important;
  color: #979ead !important;
}
.delete_icon > svg:hover {
  // border: 1px solid #d92d20 !important;
  color: #d92d20 !important;
  background-color: #fcceca;
  border-radius: 20% !important;
}

.edit_icon {
  font-size: 14px;
  color: #979ead !important;
}
.edit_icon:hover {
  color: #03b120 !important;
  background-color: #dcf7e1;
  border-radius: 20% !important;
}
.info_icon {
  color: #667085 !important;
  background-color: #667085 !important;
  margin-left: 0px !important;
}
:global(.ant-empty-normal) {
  margin-bottom: 0px !important;
}
