.lady_background_welcome {
  background-color: white;
  padding: 50px 100px 100px 100px;
}
.user_name_subText {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #667085;
  // width: 800px;------------
}

//-------------------------------
.title_container {
  display: flex;
  justify-content: space-between;
  // font-family: sfprotext-bold font-22;
  // font-size: 22px;
}
.text_Title {
  color: #2264ae;
  margin-top: 40px;
}
.dateRange_picker {
  height: 32px !important;
  //line-height: 1;
  padding: 6px 9px 6px;
}
.card_container {
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  padding: 1rem 1rem;
  // display: flex;
  //justify-content: space-between;
}
.chartView_container {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  padding: 20px 30px 30px 20px; //1rem 2rem;
  margin-left: -8px;
  margin-right: -14px;
}
.speedometerView_container {
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 4px;
  // padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  //background: #ffffff;
}
.speedometer_container {
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
}
.speedometer_VerticalBar_container {
  background: #ffffff;
  padding: 20px;
  border-radius: 4px;
}
.tableView_container {
  box-shadow: 0px 0px 10px rgba(127, 161, 250, 0.15);
  border-radius: 8px;
  // padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  //background: #ffffff;
  margin-left: -8px;
  margin-right: -14px;
}
// .table_container {
//   background: #ffffff;
//   padding: 20px 20px 0px 20px;
//   border-radius: 4px;
// }
.horiz_container {
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
}
.table_container {
  background: #ffffff;
  padding: 20px 20px 0px 20px;
  border-radius: 4px;
}
.horiz_container1 {
  background: #ffffff;
  padding: 10px 20px 20px 20px;
  border-radius: 8px;
}

.horiz_container2 {
  background: #ffffff;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 0px !important;
  border-radius: 8px;
  // padding-top: -50px;
}

.textAligment {
  align-items: normal;
}
.button_import_style {
  label {
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 20px !important; //Tab padding
    line-height: 32px !important;
    background-color: #1c4e9d;
    color: white;
  }
  label:hover {
    background-color: white;
    color: #1c4e9d;
  }
}
.cardStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 10px 24px 10px 24px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  margin-bottom: 10px;
}
.cardStyles > div {
  padding: 20px 24px 20px 24px !important;
  width: 100%;
}
.cardStyles_stakeHolders {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 10px 24px 10px 24px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  margin-bottom: 10px;
}
.cardStyles_stakeHolders > div {
  padding: 0px 24px 0px 40px !important;
}
.cardStyles_paiChart {
  width: 100%;
  display: flex;
  // flex-direction: column;
  //align-items: flex-start;
  //padding: 10px 24px 10px 24px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 10px;
  margin-bottom: 10px;
}
.cardStyles_paiChart > div {
  padding: 0px 24px 0px 40px !important;
  padding-top: -50px !important;
  display: flex;
  justify-content: center;
  width: 100%;
}

.button_import_style_isMobile {
  label {
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 20px !important; //Tab padding
    line-height: 32px !important;
    background-color: #1c4e9d;
    color: white;
    text-align: center;
    //width: 87%;
    display: block;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }
  label:hover {
    background-color: white;
    color: #1c4e9d;
  }
}

@media only screen and (max-width: 1348px) and (min-width: 1194px) {
}
@media only screen and (max-width: 2260px) and (min-width: 1560px) {
}
@media only screen and (max-width: 1560px) and (min-width: 1348px) {
}
@media only screen and (max-width: 1198px) and (min-width: 770px) {
}
/* For Mobile */
@media (max-width: 575px) {
  .lady_background_welcome {
    padding: 20px 10px;
  }
  .title_container {
    display: flex;
    flex-direction: column;
    justify-content: start;
    align-items: start;
  }
  .text_Title {
    color: #2264ae;
    margin-top: 10px;
    margin-bottom: 5px;
  }
  .dateRange_container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    //align-items: center;
  }
  .row_display_isMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .radio_button {
    width: 300px;
    border-radius: 4px;
  }
  .user_name_subText {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #667085;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .count_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  :global .ant-picker-panel-container .ant-picker-panels {
    flex-direction: column;
  }
  .cardStyles_paiChart > div {
    padding: 40px 24px 20px 40px !important;
  }
  .tableView_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .horiz_container {
    padding: 10px;
    max-width: 100%;
    width: 100%;
  }
  // .cardStatus_container {
  //   margin-top: 45px;
  //   margin-bottom: 20px;
  // }
  // .user_name {
  //   text-align: center;
  //   font-size: 28px;
  //   font-weight: 600;
  // }
  // .user_name_subText {
  //   margin-top: 10px;
  //   font-size: 16px;
  //   font-weight: 400;
  //   color: #667085;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  // }
  // // .policies_background {
  // //   padding: 10px 10px 30px 10px;
  // // }
  // // .policies_title {
  // //   margin-top: 10px;
  // //   display: flex;
  // //   justify-content: center;
  // //   align-items: center;
  // //   text-align: center;
  // // }
  // // .policies_text {
  // //   margin-top: 0px;
  // //   text-align: center;
  // // }
  // // .Receive_container {
  // //   margin-top: 20px;
  // // }
  // // .declare_gift_button_container {
  // //   display: flex;
  // //   //flex-direction: column;
  // //   justify-content: center;
  // //   align-items: center;
  // //   text-align: center;
  // // }
  // // .declare_gift_button_containerCEC {
  // //   display: flex;
  // //   flex-direction: column;
  // //   justify-content: center;
  // //   align-items: center;
  // //   text-align: center;
  // // }
}
// @media only screen and (max-width: 915px) and (min-width: 412px) {
//   .cardStatus_container {
//     margin-top: 70px;
//     margin-bottom: 20px;
//   }
// }
