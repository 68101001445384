.date_styles label {
  font-size: 13px !important;
}
.date_styles > div > div > div > div {
  height: 30px !important;
  width: 100% !important;
}
/* .date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
} */
.datePicker_styles > div > input {
  font-size: 12px !important;
  height: 24px !important;
  width: 100% !important;
}
.content {
  padding: 50px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}
.submit_button {
  padding: 0px 40px;
  height: 45px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.cancel_button {
  padding: 0px 40px;
  height: 45px;
  background: white;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #2264ae;
  margin-right: 15px;
}
.cancel_button:hover {
  background: #2264ae;
  color: white;
}
