//@import 'styles/variables.less';

.container {
  .menu_container {
    .menu_item {
      display: flex;
      padding: 10px 12px;

      .menu_item_icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }

      &.selected {
        .menu_item_icon {
          color: #090 !important;
        }
      }
    }
  }

  .footer_container {
    .menu_item {
      display: flex;
      padding: 8px 12px;

      .menu_item_icon {
        width: 24px;
        height: 24px;
        margin-right: 12px;
      }

      &.selected {
        .menu_item_icon {
          color: #090 !important;
        }
      }
    }

    .user_container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 8px;

      .avatar {
        background: #323282;
        color: #fff;
        height: 40px;
        width: 40px;
        line-height: 40px;
      }

      .logout_btn_container {
        width: 24px;
        height: 24px;
      }
    }
  }
}
.gift_icon_size {
  width: 16px;
  margin-right: 8px;
  color: #323282;
}
.gift_icon_size_admin {
  width: 35px;
}