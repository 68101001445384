.container {
  padding: 25px 100px;
}
.row_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.myGift_title {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}
.myGift_text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.declareGift_button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: 150px;
  height: 40px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  color: white;
  font-weight: 600;
}

.tabs_container {
  margin-top: 35px;
}

.cardStyles {
  width: 100%;
  top: 10px;
  margin-bottom: 20px;
}
.cardStyles > div {
  padding-top: 10px !important;
}
.table_container {
  //margin-top: 16px;
  margin-left: 0px !important;
}
.table_container th {
  font-size: 12px;
  //
}
.table_container td {
  font-size: 12px;
}
.status_style {
  text-transform: uppercase !important;
  font-size: 11px !important;
}
.searchbar_div {
  width: 400px;
}
.searchbar > div > span {
  height: 34px;
}
.searchbar > div > span > input {
  font-size: 12px;
  // margin-left: 12px;
}
.searchbar > div > span {
  font-size: 12px !important;
}


/**************Add New Style***********/
.page_title {
  color: #2364ad;
  font-weight: bold;
  font-size: 24px;
  line-height: 38px;
}
.page_sub_title {
  color: #667085;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  margin-top: -2px;
}
.Gate_pass_req_title {
  color: rgba(0, 0, 0, 0.7);
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 24px;
}
.Gate_pass_req_sub_title {
  color: #667085;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  margin-top: 2px;
}

.button_import_style {
  label {
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 20px !important; //Tab padding
    line-height: 32px !important;
    background-color: #1c4e9d;
    color: white;
  }
  label:hover {
    background-color: white;
    color: #1c4e9d;
  }
}

.customButton_import_style {
  font-size: 12px !important;
  width: 90px;
  height: 30px !important;
  padding: 0px 19px !important;
  padding-left: 14px !important;
}
.customButton_style_secondary {
  font-size: 12.5px !important;
  font-weight: 600 !important;
  background-color: #1c4e9d;
  color: white;
  height: 32px !important;
}
.customButton_style_secondary:hover {
  background-color: white;
  color: #1c4e9d;
}

.delete_icon > svg {
  height: 11.5px !important;
  width: 11.5px !important;
  color: #979ead !important;
}
.delete_icon > svg:hover {
  // border: 1px solid #d92d20 !important;
  color: #d92d20 !important;
  background-color: #fcceca;
  border-radius: 20% !important;
}

.edit_icon {
  font-size: 14px;
  color: #979ead !important;
}
.edit_icon:hover {
  color: #03b120 !important;
  background-color: #dcf7e1;
  border-radius: 20% !important;
}
.info_icon {
  color: #667085 !important;
  background-color: #667085 !important;
  margin-left: 0px !important;
}
:global(.ant-empty-normal) {
  margin-bottom: 0px !important;
}

.myGift_title_isMobile {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #101828;
  margin-left: 5px;
}
.myGift_text_isMobile {
  font-size: 11px;
  font-weight: 400;
  line-height: 22px;
  color: #667085;
  margin-left: 5px;
  margin-bottom: 15px;
}
.tabs_container_isMobile {
  margin-top: 15px;
}
.searchbar_isMobile {
  width: 100%;
  margin-bottom: 8px;
}
.button_import_style_isMobile {
  label {
    font-size: 13px !important;
    font-weight: 600 !important;
    height: 34px !important;
    padding: 0px 20px !important; //Tab padding
    line-height: 32px !important;
    background-color: #1c4e9d;
    color: white;
    text-align: center;
    display: block;
    margin-bottom: 10px;
  }
  label:hover {
    background-color: white;
    color: #1c4e9d;
  }
}

/* For Mobile */
@media (max-width: 575px) {
  .container {
    padding: 25px 10px;
  }
  // .row_display_isMobile {
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;
  //width: 500px !important;
  //}
  // .seatchbar_container {
  //   flex-direction: column;
  // }
  // .searchbar {
  //   text-align: center;
  // }
  .actionButton {
    height: 34px;
    margin-left: 12px;
    font-size: 12px;
    text-align: center;
    padding-top: 6px;
    background-color: #2264ae;
    color: white;
    font-weight: 600;
    font-family: 'SFProText Regular';
  }
  .radio_button {
    width: 300px;
    border-radius: 4px;
  }
  .declareGift_div {
    align-items: center;
  }
  :global .ant-radio-button-wrapper:first-child {
    border-radius: 4px !important;
  }
  :global .ant-radio-button-wrapper:last-child {
    border-radius: 4px !important;
  }
  .declareGift_button_isMobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
  }
  .declareGift_button {
    width: 300px;
    border-radius: 4px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .searchbar {
    width: 110px;
  }
  .actionButton {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .searchbar {
    width: 140px;
  }
  .actionButton {
    height: 34px;
    margin-left: 12px;
    font-size: 11px;
    text-align: center;
    padding-top: 6px;
    padding-left: 8px;
    padding-right: 8px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1024px) {
  .searchbar {
    width: 200px;
  }
  .actionButton {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1025px) and (max-width: 1199px) {
  .searchbar {
    width: 250px;
  }
  .actionButton {
    margin-left: 12px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1599px) {
}
@media only screen and (min-width: 1600px) {
}
