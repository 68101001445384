.container {
  background-color: #eef3f9;
  padding: 50px 100px;
}

.policies_background {
  background-color: #eef3f9;
  padding-top: 50px;
  padding-bottom: 50px;
}
.policies_title {
  margin-top: 20px;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
}
.policies_text {
  margin-top: 20px;
  width: 350px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.Receive_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #101828;
}
.Receive_text {
  top: 20px;
  width: 650px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.gift_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #101828;
}
.gift_text {
  top: 20px;
  width: 650px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.learn_arrow {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}
.learn_more {
  color: #2264ae;
  margin-right: 4px;
}
.giftCard_div {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 40px 30px 40px;

  // width: 384px;
  // height: 272px;

  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
}
.cards_background {
  //background-color: white;
  //padding: 50px 100px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
  gap: 24px;
}
.giftCard_img {
  width: 200px;
  height: 200px;
  // padding-top: 10px ;
  margin-left: 30px;
  padding-bottom: 10px;
}
.pricingCard_img {
  width: 200px;
  height: 200px;
  // padding-top: 10px ;
  padding-left: 25px;
  padding-bottom: 10px;
}
.gift_box_img {
  width: 200px;
  height: 200px;
  // padding-top: 10px ;
  margin-left: -50px;
  padding-bottom: 10px;
}
.giftCard_img_button {
  //   display: flex;
  // flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  width: 250px;
  // height: 40px;
  background: #f5f5f5;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 12px;
  font-weight: 600;
}

@media only screen and (max-width: 1348px) and (min-width: 1194px) {
}
@media only screen and (max-width: 2260px) and (min-width: 1560px) {
}
@media only screen and (max-width: 1560px) and (min-width: 1348px) {
}
@media only screen and (max-width: 1198px) and (min-width: 770px) {
}
/* For Mobile */
@media (max-width: 575px) {
  .container {
    padding: 30px 10px 0px 10px;
  }
  .cards_background {
    width: 100%;
    justify-content: space-evenly;
  }
  .policies_title {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .policies_text {
    margin-top: 0px;
    text-align: center;
  }
  .Receive_title_isMobile {
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    color: #101828;
  }
  .Receive_text_isMobile {
    top: 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #667085;
  }
}

/* 
.lady_img {
  width: 20%;
  height: 20%;
}
.user_name {
  font-size: 38px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -0.02em;
}
.user_name_subText {
  font-size: 16px;
  font-weight: 400;
  color: #667085;
  width: 800px;
}
.user_name_button {
  top: 20px;
  height: 38px;
  //width: 143px;
  border-radius: 8px;
  padding: 12px 20px 12px 20px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.user_name_button1 {
  top: 20px;
  height: 38px;
  //width: 143px;
  border-radius: 8px;
  padding: 12px 20px 12px 20px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  margin-left: 15px;
} */
