.container_stepper {
  margin-top: 10px;
  padding: 20px 100px 10px 100px;
  position: fixed;
  z-index: 1;
  width: 100%;
  background-color: #f0f2f5;
}
.container_stepper_div {
  width: 500px;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-left: 300px;
}
.container_bulk_upload {
  padding: 30px 100px 50px 100px;
}
/* .container {
 padding: 70px 100px 50px 100px;
} */

.title {
  font-weight: 700;
  font-size: 30px;
  line-height: 38px;
  color: #101828;
}
.subTitle {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
}
.cardStyles {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 0px !important;
  background: #ffffff;
  border: 1px solid #eaecf0;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08),
    0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  border-radius: 16px;
  margin-bottom: 15px;
}
.cardStyles > div {
  padding: 16px 24px 12px 24px !important;
  width: 100%;
}
.card_content {
  width: 100%;
  //margin-top: 10px;
}
.card_title {
  font-weight: 500;
  font-size: 20px;
  line-height: 20px;
  color: #344054 !important;
}

.margin_Top {
  //margin-top: 20px;
  width: 100%;
}

.Radio_button_margin {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: flex-start;
  margin-top: 20px;
}
.card_Radio {
  width: 100%;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #344054;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  justify-items: flex-start;
}

.submit_button {
  padding: 0px 40px;
  height: 45px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: white;
}
.cancel_button {
  padding: 0px 40px;
  height: 45px;
  background: white;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  font-size: 14px;
  font-weight: 600;
  color: #2264ae;
  margin-right: 15px;
}
.cancel_button:hover {
  background: #2264ae;
  color: white;
}
.select_styles {
  padding-bottom: 0px !important;
  margin-bottom: 0px !important;
}
.uploadButton {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.input_styles label {
  font-size: 12px !important;
}
.input_styles > div > div > div > span {
  height: 38px !important;
  font-size: 12px !important;
  width: 100% !important;
  font-family: 'SFProText Regular';
}
.input_styles > div > div > div > span > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.select_styles > div > div > div > div > div {
  //height: 34px !important;
  font-size: 12px;
  font-family: 'SFProText Regular';
}
.select_styles > div > div > div > div > div > span {
  //line-height: 31px !important;
  font-family: 'SFProText Regular';
}
.date_styles > div > div > div > div {
  height: 33px !important;
  width: 100% !important;
}
.date_styles > div > div > div > div > div > input {
  font-size: 12px !important;
  font-family: 'SFProText Regular';
}
.date_styles label {
  font-size: 12px !important;
}
// .textarea_styles {
//   font-size: 12px;
//   color: black;
//   font-family: 'SFProText Regular';
// }
.title_section {
  font-weight: 600;
  font-size: 22px;
  line-height: 38px;
  color: #2264ae;
  font-family: 'SFProText Regular';
}
.divider_margin {
  margin-top: 0px !important;
}
//---------------------------------
.progressMainWrapper {
  background-color: #2264ae;
  //height: 15px;
  position: sticky;
  top: 60px;
  left: 0;
  z-index: 1;
  width: 100%;
}

.progressMainStyle {
  height: 7px;
  background-color: #00cc83;
  width: 15%;
}
:global(.ant-steps-item-finish .ant-steps-item-icon) {
  border-color: rgb(3, 170, 3);
}
.auto_card_content {
  font-size: 14px;
  font-weight: 600;
  font-family: 'SFProText Regular';
  color: rgba(0, 0, 0, 0.5);
  margin-bottom: 5px;
}
.bulk_upload {
  float: right;
  margin-right: 10px;
}
.bulk_upload_a {
  font-size: 18px;
  color: #2264ae;
  font-weight: 600;
}
.bulk_upload_a:hover {
  color: #4945ff;
}
.upload_button {
  padding: 4px 8px !important;
  font-size: 12px !important;
  height: 30px !important;
  font-weight: 500;
  margin-left: 10px;
}
.upload {
  font-family: 'SFProText Regular';
  font-size: 12px;
  color: black;
  font-weight: 600;
}
.custom_file_upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 5px 12px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  font-size: 12px !important;
  font-weight: 500;
}
.custom_file_upload:hover {
  display: inline-block;
  padding: 5px 12px;
  background-color: #1c4e9d;
  cursor: pointer;
  color: white;
  transition: all 0.4s ease-in-out;
  margin-right: 10px;
  font-size: 12px !important;
  font-weight: 500;
}
:global(.ant-form input[type='file']) {
  display: none;
}
.delete_uploaded_file {
  margin-right: 4px;
}
.delete_uploaded_file:hover {
  color: red;
}

/* For Mobile */
@media (max-width: 575px) {
  .container_bulk_upload {
    padding: 30px 10px 50px 10px;
  }
  .browse_container {
    margin: 10px;
  }
}
