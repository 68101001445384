.lady_background_welcome {
  background-color: white;
  padding: 50px 20px 50px 100px;
}
.lady_img {
  width: 90%; //20%
  height: 90%; //20%
}
.user_name {
  font-size: 38px;
  font-weight: 400;
  line-height: 60px;
  letter-spacing: -0.02em;
}
.user_name_subText {
  margin-top: 10px;
  font-size: 16px;
  font-weight: 400;
  color: #667085;
  // width: 800px;------------
}
.user_name_button {
  top: 20px;
  height: 38px;
  //width: 143px;
  border-radius: 8px;
  padding: 12px 20px 12px 20px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
}
.user_name_button1 {
  top: 20px;
  height: 38px;
  //width: 143px;
  border-radius: 8px;
  padding: 12px 20px 12px 20px;
  background: #2264ae;
  border: 1px solid #2264ae;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: #ffffff;
  margin-left: 15px;
}
.policies_background {
  background-color: #eef3f9;
  padding: 50px 10px 50px 100px;
}
.policies_title {
  margin-top: 20px;
  font-weight: 600;
  font-size: 36px;
  line-height: 44px;
  letter-spacing: -0.02em;
  color: #101828;
}
.policies_text {
  margin-top: 20px;
  width: 350px;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #667085;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.Receive_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #101828;
}
.Receive_text {
  top: 20px;
  //width: 650px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.gift_title {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #101828;
}
.gift_text {
  top: 20px;
  //width: 650px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #667085;
  /* Inside auto layout */
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;
}
.learn_arrow {
  flex-direction: row;
  display: flex;
  justify-content: flex-end;
}
.learn_more {
  color: #2264ae;
  margin-right: 4px;
}
.a_color {
  color: #2264ae;
}
@media only screen and (max-width: 1348px) and (min-width: 1194px) {
}
@media only screen and (max-width: 2260px) and (min-width: 1560px) {
}
@media only screen and (max-width: 1560px) and (min-width: 1348px) {
}
@media only screen and (max-width: 1198px) and (min-width: 770px) {
}
/* For Mobile */
@media (max-width: 575px) {
  .lady_background_welcome {
    padding: 25px 10px;
  }
  .lady_img {
    width: 100%; //20%
    height: 100%; //20%
  }
  .cardStatus_container {
    margin-top: 45px;
    margin-bottom: 20px;
  }
  .user_name {
    text-align: center;
    font-size: 28px;
    font-weight: 600;
  }
  .user_name_subText {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 400;
    color: #667085;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .policies_background {
    padding: 10px 10px 30px 10px;
  }
  .policies_title {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .policies_text {
    margin-top: 0px;
    text-align: center;
  }
  .Receive_container {
    margin-top: 20px;
  }
  .declare_gift_button_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .declare_gift_button_containerCEC {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  .user_name_button {
    //  margin-bottom: 15px;
    // margin-left: 15px;
  }
  .user_name_button1 {
    // margin-left: 15px;
  }
}
@media only screen and (max-width: 915px) and (min-width: 412px) {
  .cardStatus_container {
    margin-top: 70px;
    margin-bottom: 20px;
  }
}
