.user_menu_container {
  // background: #FAFAFA;
  // border: 1px solid #CFD7DF;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 7px 10px;
  .user_menu_avatar {
    background: #F6F0A7;
    margin-right: 3px;
    color: #DCBE43;
  }
}


/* menuItems > li > span {
  font-size: 30px;
  color: #fff !important;
}

.dashboard_icon {
  color: #abb4be !important;
  //background-color: red !important;
}
.dashboard_icon {
  color: white !important;
  background-color: white !important;
}
.dashboard_icon:hover {
  color: white !important;
  background-color: #2364ad !important;
  span > svg {
    color: white;
  }
}
.dashboard_icon:active {
  background-color: white;
  color: #2364ad !important;
}
.dashboard_icon > span > svg:hover {
  color: white;
}
.dashboard_icon > span > svg {
  color: #2364ad;
  margin-bottom: 7px;
} */